import { createSlice } from '@reduxjs/toolkit';

function getClientDate() {
    let startDate = localStorage.getItem("startDate");
    let oneMonthAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate())

    if (startDate) {
        let storedDate = new Date(new Date(startDate).getTime() - 330 * 60000);

        if (storedDate > oneMonthAgo) {
            return storedDate;
        } else {
            return oneMonthAgo;
        }
    } else {
        return oneMonthAgo;
    }
}

const initialState = {
    startDate: getClientDate() || new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
    endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    storeNumbers: 'all'
};

const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState,
    reducers: {
        setDateRangeInner: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.storeNumbers = action.payload.storeNumbers;
        }
    }
});

export const { setDateRangeInner } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;